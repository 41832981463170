@import '_valiables';

li.notification-item {
  background-color: $white;
  padding: 8px 20px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &.unread {
    // background-color: rgba($warning, 0.15);
    border-left: 8px solid $warning;
    padding-left: 12px;
  }
  p.title {
    span.unread {
      background-color: $warning;
      padding: 2px 5px;
      border-radius: 2px;
      font-weight: bold;
      font-size: 0.8rem;
      color: $white;
      margin-right: 10px;
    }
  }
  p.datetime {
    font-size: 0.8rem;
    color: $grey;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding-top: 2px;
    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

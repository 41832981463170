@import '_valiables';

.app-layout {
  background: linear-gradient(-45deg, $black 0%, $bg-dark 30%, $bg-light 95%, $black 120%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .app-layout-content {
    width: 100%;
    height: 100%;
    background-color: $white;
    max-width: $view-max-width;
    max-height: $view-max-height;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 0 0 3px rgb(255 255 255 / 30%), 0 0 0 12px rgb(255 255 255 / 20%), 0 0 200px 0px rgb(0 0 0 / 60%);
    position: relative;
    @include mq {
      border-radius: 0;
      box-shadow: none;
    }
  }
}

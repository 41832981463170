$white: #ffffff;
$smoke: #f0f0f0;
$dark: #223354;
$dark50: #9099a9;
$black: #000000;

$grey: #6e7781;
$grey20: #e2e4e6;
$grey40: #c5c9cd;
$grey150: #373b40;

$blue: #65aadd;
$blue-dark: #2290e5;

$green: #aacf52;
$green-dark: #87ba12;

$yellow: #f9c158;
$yellow-dark: #ffa600;

$vivid: #00e0ff;
$lightblue: #0093ff;
$navy: #0d2f59;

$bg-light: #151f32;
$bg-dark: #0d131d;

$primary: #0d2f59;
$secondary: #0093ff;

$warning80: #f7767f;
$warning: #f5545f;
$warning110: #dc4c56;

$info: #65aadd;
$success: #87ba12;

$header-height: 70px;

$padding: 20px;
$btn-size: 44px;

$view-max-height: 844px;
$view-max-width: 390px;

@mixin mq() {
  @media (max-width: $view-max-width) {
    @content;
  }
}

@mixin overlay() {
  top: calc((100% - $view-max-height) / 2) !important;
  bottom: calc((100% - $view-max-height) / 2) !important;
  left: calc((100% - $view-max-width) / 2) !important;
  right: calc((100% - $view-max-width) / 2) !important;
  border-radius: 30px;
  overflow: hidden;
  @media (max-width: $view-max-width) {
    border-radius: 0;
    left: 0 !important;
    right: 0 !important;
  }
  @media (max-height: $view-max-height) {
    top: 0 !important;
    bottom: 0 !important;
  }
}

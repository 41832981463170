@import 'styles/_valiables';

.calendar-header {
  width: 100%;
  background-color: $primary;
  color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  .globle-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 5px;
    a {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .globle-user-status-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      .user-status-btn-rest {
        color: white;
        border-color: white;
        font-weight: bold;
      }
      .user-status-btn-leave {
        background-color: white;
        color: $primary;
        font-weight: bold;
      }
      .user-status-btn-come {
        font-weight: bold;
      }
    }
  }
  .calendar-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  ul.calendar-paging {
    display: flex;
    padding: 0 20px;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    .calendar-paging-btn {
      width: 40px;
      height: 40px;
      font-weight: bold;
      // background-color: mix($primary, $white, 95%);
      padding: 0;
      margin: 0;
      min-width: 0;
      flex: 0 0 40px;
      border: none;
      font-size: 18px;
      letter-spacing: -0.05em;
      &.-is-active {
        background-color: $white;
        color: $primary;
      }
      .sub-weekday{
        position: absolute;
        font-size: 9px;
        top: -3px;
        opacity: 0.5;
      }
    }
  }
}

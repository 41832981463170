@import 'styles/_valiables';

.position-action-btns {
  display: flex;
  padding: 0 $padding;
  gap: 10px;
  span.sub-info {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    bottom: -15px;
    &.-pause {
      color: $warning;
    }
    &.-play {
      color: #3d83a1;
    }
  }
  .cancel-btn {
    border: 2px solid $warning;
    &:hover {
      border-color: $warning80;
      color: $warning80;
    }
  }
  .pause-btn {
    background-color: $warning;
    color: $white;
    flex: 0 0 $btn-size;
    width: $btn-size;
    &:hover {
      background-color: $warning80;
    }
  }
  .play-btn {
    color: $white;
    flex: 0 0 $btn-size;
    width: $btn-size;
    background: linear-gradient(276.3deg, #2a5c74 0%, #51aed4 96.46%);
  }
  .swipe-btn {
    font-weight: bold;
    border-radius: 50px;
    &.-unload {
      background: linear-gradient(276.3deg, #2a5c74 0%, #51aed4 96.46%);
    }
    &.-load {
      background: linear-gradient(96.48deg, #87ba11 -6.48%, #4f7209 100%);
    }
    &.disable {
      background: $grey20;
    }
    &.-redo{
      background: $warning;
    }
  }
}

@import '_valiables';

.instruction-header{
  background-color: $dark;
  color: $white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  .order-date{
    font-size: 1.2rem;
  }
  .notification-date{
    font-size: 0.8rem;
  }
}
@import '_valiables';

.page-notification {
  ul {
    list-style: none;
    li {
      p.date {
        margin: 30px 0 5px 0;
        color: $grey;
        font-size: 0.8rem;
      }
    }
  }
}

@import '_valiables';

.luggage-item {
  // border-top: 1px solid $grey40;
  list-style-position: inside;
  padding: 2px 5px;
  color: $grey;
  font-weight: 400;
  font-size: 0.8rem;
  &:nth-child(odd) {
    background-color: rgba($dark, 0.05);
  }
  span.id {
    display: inline-block;
    width: 36px;
    text-align: center;
    border-radius: 2px;
    border: 0.5px solid $grey40;
    margin-left: 5px;
    margin-right: 10px;
  }
  span.name {
    margin-right: 10px;
    font-weight: 700;
  }
  span.weight {
  }
}

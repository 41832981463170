@import '_valiables';

.order-item {
  width: 100%;
  display: flex;
  background-color: $white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s;
  .left {
    height: 24px;
    .check {
      height: 24px;
    }
  }
  .right {
    flex-grow: 1;
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      svg {
        width: 0.7em;
        height: 0.7em;
        fill: $grey40;
      }
    }
    .work-in-progress {
      background-color: $warning;
      color: $white;
      font-size: 0.8rem;
      padding: 3px 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      svg {
        fill: $white;
      }
      span {
        padding-left: 10px;
      }
    }
    .header {
      display: flex;
      width: 100%;
      padding-right: 5px;
      .type {
        padding: 0 10px;
        border-radius: 3px;
        font-size: 0.8rem;
        height: 1.5rem;
        line-height: 1.5rem;
        margin-right: 10px;
        color: $white;
        &.green {
          background-color: $green-dark;
        }
        &.blue {
          background-color: $blue-dark;
        }
        &.finished {
          color: $dark;
          padding: 0;
          font-size: 1rem;
        }
      }
      .plan-time {
        margin-left: auto;
        span {
          &.outtime {
            text-decoration: line-through;
            color: rgba($grey, 0.6);
          }
        }
      }
    }
    .col {
      border-top: 1px solid $grey20;
      padding-top: 5px;
      margin-top: 10px;
      padding-bottom: 10px;
      p.destination {
        font-size: 1.1rem;
      }
      p.address,
      p.luggage-type {
        font-size: 0.8rem;
        color: $grey;
      }
    }
    .luggage-list {
      margin-top: 5px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    .action-btns {
      display: flex;
      gap: 5px;
      padding: 10px 0;
      .btn {
        font-weight: bold;
        font-size: 0.8rem;
        height: 45px;
        padding: 0;
        flex-grow: 1;
        // &.green {
        //   background-color: $green-dark;
        // }
        // &.blue {
        //   background-color: $blue-dark;
        // }
      }
    }
  }
}
.order-item.finished {
  .right {
    .col {
      p.destination,
      p.address,
      p.luggage-type {
        // font-size: 0.8rem;
        color: rgba($grey, 0.6);
      }
    }
  }
}

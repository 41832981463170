// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700;900&family=Noto+Sans+JP:wght@100;400;700;900&display=swap');
// font-family: 'Noto Sans JP', sans-serif;
// font-family: 'Montserrat',sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700;900&family=M+PLUS+1p:wght@100;400;700;900&display=swap');
// font-family: 'M PLUS 1p', sans-serif;

@import '_valiables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
  color: $dark;
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: 700;
}

::-moz-selection {
  color: $white;
  background: $dark;
}

::selection {
  color: $white;
  background: $dark;
}

a {
  color: $dark;
  &:hover {
    text-decoration: none;
  }
}

@import '_valiables';

.cancel-form {
  padding: 0 $padding;
  textarea {
    outline: none;
    width: 100%;
    resize: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    letter-spacing: 0.05em;
    border: 1px solid $dark;
    box-shadow: 0 0 0 5px transparent;
    transition: box-shadow 0.3s, border 0.3s;

    &:focus {
      box-shadow: 0 0 0 5px rgba($warning, 0.3);
      border: 1px solid $warning;
    }
  }
  .cancel-btns {
    display: flex;
    gap: 10px;
    .cancel-btn {
      font-weight: bold;
      height: 45px;
      font-size: 1rem;
      margin-top: 10px;
    }
  }
}

@import 'styles/_valiables';

.position-list {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 100px;
  .display-date-label {
    color: $grey;
    padding: 10px $padding;
    font-size: 14px;
  }
}
.position-action-drawer {
  @include overlay;

  .MuiDrawer-paperAnchorBottom {
    position: absolute !important;
    padding: 10px 0 $padding 0;
    border-radius: 12px 12px 0 0;
  }
}
.position-item-wrapper {
  display: flex;
  border-bottom: 1px solid $grey40;
  margin-bottom: 10px;
  align-items: center;
  .position-item {
    border: none;
    cursor: auto;
  }
  .detail-btn {
    color: $grey40;
    margin-right: 20px;
    span.icon-button-text {
      position: absolute;
      font-size: 10px;
      font-weight: bold;
      transform: translateY(100%);
    }
  }
}

.position-detail-drawer {
  @include overlay;

  .MuiDrawer-paperAnchorBottom {
    position: absolute !important;
    padding: 10px 0 $padding 0;
    height: 100%;
  }
}

.position-inprogress-drawer {
  @include overlay;
  .MuiDrawer-paperAnchorBottom {
    position: absolute !important;
    padding: 10px 0 $padding 0;
    border-radius: 12px 12px 0 0;
  }
  .position-inprogress-title {
    font-size: 1;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid $grey20;
  }
}

@import '_valiables';

.cancel-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  &.slideIn {
    opacity: 1;
    &.slideOut {
      opacity: 0;
    }
  }
}
.cancel-modal-bg h2{
  font-size: 1.2rem;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $dark;
}
.cancel-modal-bg .cancel-modal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -10px 50px 0 rgba($dark, 0.2);
  border-radius: 20px 20px 0 0;
  padding: 20px;

  display: flex;
  flex-direction: column;
  background-color: $white;
  margin-top: 10px;
  gap: 10px;

  flex-grow: 1;
  transform: translate(0, 50%);

  transition: transform 0.3s;
  &.slideIn {
    transform: translate(0, 0);
    &.slideOut {
      transform: translate(0, 50%);
    }
  }
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    svg {
      width: 0.7em;
      height: 0.7em;
      fill: $grey40;
    }
  }
  .header {
    display: flex;
    width: 100%;
    padding-right: 5px;
    .type {
      padding: 0 10px;
      border-radius: 3px;
      font-size: 0.8rem;
      height: 1.5rem;
      line-height: 1.5rem;
      margin-right: 10px;
      color: $white;
      &.green {
        background-color: $green-dark;
      }
      &.blue {
        background-color: $blue-dark;
      }
      &.finished {
        color: $dark;
        padding: 0;
        font-size: 1rem;
      }
    }
    .plan-time {
      margin-left: auto;
      span {
        &.outtime {
          text-decoration: line-through;
          color: rgba($grey, 0.6);
        }
      }
    }
  }
  .col {
    border-top: 1px solid $grey20;
    padding-top: 5px;
    margin-top: 10px;
    padding-bottom: 10px;
    p.destination {
      font-size: 1.1rem;
    }
    p.address,
    p.luggage-type {
      font-size: 0.8rem;
      color: $grey;
    }
  }
  .luggage-list {
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

@import '_valiables';

.page-login {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 40px 50px 30px 50px;
  background-color: $white;
  img {
    margin-bottom: auto;
  }
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 30px;
    color: $grey;
    span {
      display: inline-block;
    }
  }
  .login-input-wrapper {
    width: 100%;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .login-btn {
      margin-top: 30px;
      height: 45px;
      border-radius: 5px;
      font-weight: 700;
      letter-spacing: 0.15em;
    }
    a {
      font-size: 0.8rem;
      margin-top: 10px;
    }
  }
  .login-sub-info {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      height: 2px;
      width: 100px;
      background-color: $grey20;
      transform: translate(-50%, -20px);
    }
    a {
      font-size: 0.8rem;
      color: $grey;
    }
  }
}

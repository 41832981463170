@import 'styles/_valiables';

.operation-item {
  padding: 10px $padding;
  &:not(:first-child) {
    border-top: 1px solid $grey40;
  }
  &:last-child {
    border-bottom: 1px solid $grey40;
  }
  .row {
    display: grid;
    grid-template-columns: 60px 1fr;
    font-size: 14px;
    column-gap: 20px;
    line-height: 135%;
    p.label {
      color: $grey40;
      font-weight: bold;
      text-align: justify;
      text-align-last: justify;
    }
    p.value {
      color: $dark;
    }
  }
}

@import '_valiables';

.instruction-meta {
  margin: 10px 0;
  background-color: $white;
  border-radius: 10px;
  padding: 0 10px;
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 5px 10px;
    &:not(:last-child) {
      border-bottom: 1px solid $grey20;
    }

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      svg {
        width: 1rem;
        height: 1rem;
        fill: $grey40;
      }
      span {
        font-size: 0.8rem;
        color: $dark50;
        margin-right: 5px;
      }
    }
  }
}

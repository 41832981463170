@import '_valiables';

.app-layout {
  .app-layout-content {
    header {
      z-index: 10;
    }
    .app-content {
      background-color: $smoke;
      width: 100%;
      flex-grow: 1;
      min-height: 0;
      .cancel-modal-bg {
        z-index: 11;
      }
    }
    footer {
      z-index: 1;
    }
  }
}

ul {
  list-style: none;
}
@import '_valiables';
.page-instruction-list {
  ul {
    list-style: none;
    margin-top: 10px;
  }

  .instruction-type-tabs {
    margin-top: 20px;
    display: flex;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    padding: 5px;
    .tab {
      flex-grow: 1;
      text-align: center;
      color: $grey;
      border-radius: 5px;
      padding: 5px 0;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &.active {
        background-color: $dark;
        color: $white;
      }
    }
  }
}

@import 'styles/_valiables';

.position-item {
  border-top: 1px solid $grey40;
  width: 100%;
  position: relative;
  z-index: 0;
  font-weight: bold;
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid $grey40;
  }
  // &.-in-progress{
  // }
  background-color: $white;
  span.index-display {
    position: absolute;
    right: $padding;
    top: 10px;
    // z-index: -1;
    font-size: 4rem;
    line-height: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgba($dark, 0.03);
    user-select: none;
    pointer-events: none;
  }
  .status-header {
    width: calc(100% - 2 * $padding);
    background-color: $warning;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px $padding 5px $padding;
    gap: 10px;
    border-radius: 5px;
    padding: 2px 5px;
    svg {
      font-size: 18px;
    }
  }
  .label-header {
    display: flex;
    padding: 0 $padding;
    margin-bottom: 5px;
    &:first-child {
      margin-top: 10px;
    }

    span.label {
      color: $white;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 5px;
      &.-red {
        background-color: $warning;
      }
      &.-green {
        background-color: $green-dark;
      }
      &.-blue {
        background-color: $blue-dark;
      }
    }
  }
  .position-info-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 $padding;
    gap: 5px;
    &:first-child {
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
    svg {
      font-size: 18px;
      color: $grey40;
      margin-top: 3px;
    }
    &.-address {
      font-size: 14px;
      color: $dark50;
      p span {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

.position-item.-is-finished {
  background-color: transparent;
  color: $dark50;
  line-height: 1.5rem;
  font-size: 14px;
  .position-info-row {
    gap: 10px;
    flex-wrap: wrap;
    row-gap: 0;
    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 3px;
      svg {
        font-size: 18px;
        margin-top: 0px;
      }
    }
  }
}

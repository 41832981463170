@import '_valiables';

.position-detail {
  width: 100%;
  height: 100%;
  // background-color: $smoke;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr 54px;
  .position-detail-header {
    button.MuiButtonBase-root {
      color: $dark50;
      font-weight: bold;
    }
  }
  .position-detail-list {
    overflow: auto;
    padding-bottom: 100px;
  }
  .position-detail-footer {
    background-color: $white;
    box-shadow: 0 -5px 20px 0 $grey20;
    padding-top: 10px;
  }

  &.-is-finished{
    grid-template-rows: auto 1fr;
  }
}

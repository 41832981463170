@import '_valiables';

.driver-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  .img-wrapper{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 5px 0 rgba($dark, 0.1);
    margin-bottom: 10px;
    background-color: $white;
    padding: 5px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cober;
    }
  }
  p.driver-name{
    font-size: 1.2rem;
    line-height: 100%;
    span{
      font-size: 0.8rem;
    }
  }
  .vehicle-number{
    font-size: 0.8rem;
    line-height: 100%;
    color: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    svg{
      width: 0.6em;
      height: 0.6em;
    }
  }

}
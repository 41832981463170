@import '_valiables';

li.instruction-item {
  background-color: $white;
  padding: 8px 16px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &.unread {
    border-left: 8px solid $warning;
    padding-left: 8px;
  }
  &.blue {
    border-left: 8px solid $blue-dark;
    padding-left: 8px;
    svg[data-testid='RadioButtonCheckedIcon'] {
      fill: $blue-dark;
    }
  }
}

li.instruction-item {
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.1rem;
    gap: 10px;
    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      white-space: nowrap;
    }
    span {
      font-size: 0.9rem;
    }
  }
  .sub {
    display: flex;
    color: $grey;
    gap: 10px;
    padding-left: 2rem;
    &.blue {
      color: $blue-dark;
      svg {
        fill: $blue-dark;
      }
    }
    p {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      svg {
        width: 0.9rem;
        height: 0.9rem;
        fill: rgba($grey, 0.5);
      }
    }
  }
}
li.instruction-item.past {
  p.title {
    color: $grey;
    font-size: 1rem;
  }
  .sub {
    color: rgba($grey, 0.5);
  }
}
